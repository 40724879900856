<template>
  <th-wrapper
    class="m-8"
    :title="$t('common.titles.general_info.title')"
    :subtitle="$t('pages.vouchers.form.settings.description')"
  >
    <!-- Center actions -->
    <template #center>
      <!-- Active -->
      <el-checkbox id="active" v-model="form.active" class="h-10 mt-5">
        {{ $t('common.forms.labels.active') }}
      </el-checkbox>
    </template>

    <el-form ref="form" :model="form" :rules="rules">
      <el-row :gutter="20">
        <el-col :md="8">
          <!-- voucher system -->
          <el-form-item
            prop="system"
            :label="$t('pages.vouchers.form.settings.voucher_system')"
          >
            <el-select
              id="system"
              v-model="form.system"
              class="w-full"
              :placeholder="$t('common.interactions.selects.placeholder')"
              :disabled="!isNew"
              @change="inheritFromTemplate"
            >
              <el-option
                v-for="item in voucherSystems"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :md="4">
          <!-- Amount -->
          <el-form-item
            prop="amount"
            :label="$t('common.forms.labels.amount')"
            class="flex-1"
          >
            <th-currency-input
              id="amount"
              v-model="form.amount"
              class="w-full"
              :currency="form.currency"
            />
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <!-- Code -->
          <el-form-item
            prop="code"
            :label="$t('common.forms.labels.code')"
            class="flex-1"
          >
            <el-input
              id="code"
              v-model="form.code"
              class="w-full"
              :disabled="!isNew"
            />
          </el-form-item>
        </el-col>
        <el-col>
          <!-- Is campaign -->
          <el-form-item prop="is_campaign">
            <div class="flex items-center">
              <th-popover
                :text="$t('pages.voucher_systems.form.is_campaign.tooltip')"
                class="mr-2"
              />
              <el-checkbox id="is_campaign" v-model="form.is_campaign">
                {{ $t('pages.voucher_systems.form.is_campaign.label') }}
              </el-checkbox>
            </div>
          </el-form-item>
        </el-col>
      </el-row>

      <hr class="mb-8 block" />

      <el-row :gutter="20">
        <voucher-format ref="voucher-format" v-model="form" :is-new="isNew" />
      </el-row>
      <el-row v-show="!form.is_campaign" :gutter="20">
        <attribute-settings v-model="form" />
      </el-row>
    </el-form>
  </th-wrapper>
</template>

<script>
import get from 'just-safe-get'
import AttributeSettings from './attribute-settings'
import VoucherFormat from './voucher-format'
import { generateDefault, attributesToInherit } from '../../helpers'

export default {
  components: { AttributeSettings, VoucherFormat },
  props: {
    modelValue: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      resources: {},
      unwantedAtributtes: ['expires_at']
    }
  },
  computed: {
    form: {
      get() {
        return this.modelValue
      },
      set(modelValue) {
        this.$emit('update:modelValue', modelValue)
      }
    },
    isNew() {
      // cheat
      if (this.$route.params.id && this.$route.params.id === 'new') return true

      return !this.$route.params.id
    },
    voucherSystems() {
      return (this.resources.voucher_systems || []).map((voucher) => {
        return {
          value: voucher.id,
          label: voucher.name
        }
      })
    },
    rules() {
      return {
        system: [
          {
            required: true,
            message: this.$t('common.forms.rules.field_warnings.required'),
            trigger: 'change'
          }
        ]
      }
    }
  },
  mounted() {
    this.fetchResources()
  },
  methods: {
    async fetchResources() {
      try {
        this.resources = await this.$resourceFetch('voucher_systems')
      } catch (err) {
        this.$logException(err, { trackError: false })
      }
    },
    inheritFromTemplate(voucherSystemId) {
      this.form.system = voucherSystemId
      const voucherSystem = this.resources.voucher_systems?.find(
        ({ id }) => id === voucherSystemId
      )
      const templateAttributes = get(
        voucherSystem,
        'templates.0.attributes',
        {}
      )
      const valuesObj = generateDefault(templateAttributes)

      attributesToInherit.forEach((attribute) => {
        if (!this.unwantedAtributtes.includes(attribute))
          this.form[attribute] = valuesObj[attribute]
      })

      // take validity period from voucher system
      if (voucherSystem?.validity_period?.value) {
        this.form.validity_period.value = voucherSystem.validity_period.value
      }
    },
    async validate() {
      const [form, voucherFormat] = await Promise.allSettled([
        this.$refs.form.validate(),
        this.$refs['voucher-format'].validate()
      ])
      return form.value && voucherFormat.value
    }
  }
}
</script>
