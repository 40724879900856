import baseModel from '@/models/base-model-new'
import { genInitialConstrains, genValidityPeriod } from '../helpers'

export default {
  setup(model) {
    const methods = {
      parseResponse(response) {
        const data = response?.data || {}

        // init constraints
        if (!data.constraints?.time?.scheduled) {
          data.constraints = genInitialConstrains()
        }

        //init validity_period
        if (!data.validity_period) {
          data.validity_period = genValidityPeriod()
        }

        return { ...data }
      },
      parseDataBeforeSave(data) {
        //remove validity_period if an end-date is set or if we want it to last forever
        if (data.expires_at || !data.validity_period?.value) {
          data.validity_period = null
        }

        return data
      }
    }
    return baseModel.setup(model, 'vouchers', methods)
  }
}
