<template>
  <el-form ref="form" :model="modelValue" :rules="rules" class="flex w-full">
    <el-col :md="8">
      <!-- Format type -->
      <el-form-item
        prop="format_type"
        :label="$t('pages.voucher_systems.form.settings.format_type')"
      >
        <el-select
          id="format_type"
          v-model="localValue.format_type"
          class="w-full"
          :disabled="!isNew"
        >
          <el-option
            v-for="item in availableFormatTypes"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
    </el-col>
    <el-col :md="8">
      <!-- Format -->
      <el-form-item
        prop="format"
        :label="$t('pages.voucher_systems.form.settings.format')"
      >
        <el-input
          id="format"
          v-model="localValue.format"
          :disabled="!isNew || !!localValue.code"
          :placeholder="
            $t('pages.voucher_systems.form.settings.format_placeholder')
          "
          @input="setFormat"
        />
      </el-form-item>
    </el-col>
    <!-- Comment -->
    <el-col :md="8">
      <el-form-item
        prop="comment"
        :label="$t('pages.vouchers.edit.form.comment.label')"
      >
        <el-input
          id="comment"
          v-model="localValue.comment"
          :placeholder="$t('pages.vouchers.edit.form.comment.label')"
        />
      </el-form-item>
    </el-col>
  </el-form>
</template>

<script>
export default {
  props: {
    modelValue: {
      type: Object,
      default: () => ({})
    },
    isNew: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    localValue: {
      get() {
        return this.modelValue
      },
      set(modelValue) {
        this.$emit('update:modelValue', modelValue)
      }
    },
    rules() {
      return {
        format: {
          required: true,
          message: this.$t(
            'pages.vouchers.edit.form.format.validation.errors.required'
          ),
          trigger: ['blur', 'change']
        },
        format_type: {
          required: true,
          message: this.$t(
            'pages.vouchers.edit.form.format.validation.errors.required'
          ),
          trigger: ['blur', 'change']
        }
      }
    },
    translations() {
      return {
        alphanumeric: this.$t('common.forms.labels.format_types.alphanumeric'),
        numeric: this.$t('common.forms.labels.format_types.numeric'),
        alphabetic: this.$t('common.forms.labels.format_types.alphabetic'),
        scan: this.$t('pages.vouchers.edit.form.code_generation.types.scan'),
        request: this.$t(
          'pages.vouchers.edit.form.code_generation.types.request'
        )
      }
    },
    availableFormatTypes() {
      return ['alphanumeric', 'numeric', 'alphabetic'].map((value) => {
        return { value, label: this.translations[value] }
      })
    },
    codeGenerationTypes() {
      return ['scan', 'request'].map((value) => {
        return { value, label: this.translations[value] }
      })
    }
  },
  watch: {
    //if user use a specific code, the format should be as the code.
    'localValue.code'() {
      this.setFormat(this.localValue.code || '')
    },
    'localValue.system'() {
      if (this.localValue.code) {
        this.setFormat(this.localValue.code)
      }
    }
  },
  methods: {
    setFormat(value) {
      this.localValue.format = value.replace(/[^-\s]/g, 'X')
    },
    validate() {
      return new Promise((resolve) => {
        this.$refs.form.validate(resolve)
      })
    }
  }
}
</script>
