<template>
  <div v-loading="loading">
    <!-- Settings form -->
    <settings-form ref="settings-form" v-model="current" />
    <!-- Validity form -->
    <validity-form ref="validity-form" v-model="current" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import voucherModel from '../model/voucher-model'
import { generateDefault } from '../helpers'
import SettingsForm from './settings/form'
import ValidityForm from './validity/form'

export default {
  name: 'VoucherSystemsForm',
  components: { SettingsForm, ValidityForm },
  setup() {
    return voucherModel.setup(generateDefault())
  },
  data() {
    return {
      customers: []
    }
  },
  computed: {
    ...mapGetters({
      navigationAfterCreation: 'Config/getNavigationAfterCreation'
    }),
    isNew() {
      return this.isNew
    }
  },
  async mounted() {
    this.id = this.$route.params.id
    await this.fetch()
  },
  methods: {
    async submitForm() {
      const settingsFormValid = await this.validate('settings-form')
      const validityFormValid = await this.validate('validity-form')
      if (!(settingsFormValid && validityFormValid)) {
        return this.$message({
          type: 'warning',
          message: this.$t(
            'common.forms.rules.field_warnings.invalid_inputs.required'
          )
        })
      }
      const isNew = this.isNew
      const resource = this.$t('common.resource.voucher.singular')
      const { error } = await this.patch()

      //handle error
      if (error) {
        const errorMessage = isNew
          ? this.$t('common.error.action.create.single', { resource })
          : this.$t('common.error.action.update.single', { resource })
        return this.$logException(error, {
          trackError: true,
          message: errorMessage
        })
      }

      //handle success
      const successMessage = isNew
        ? this.$t('common.success.action.create.single', { resource })
        : this.$t('common.success.action.update.single', { resource })
      this.$message({
        type: 'success',
        message: successMessage
      })

      if (isNew) {
        if (this.navigationAfterCreation === 'edit') {
          if (isNew) this.routeTo(this.id)
        } else {
          this.routeTo()
        }
      }
    },
    validate(formName, cb) {
      return this.$refs[formName].validate(cb)
    },
    routeTo(path) {
      if (!path) return this.$router.push({ name: 'vouchers-list' })
      this.$router.push({ name: 'voucher-edit', params: { id: path } })
    },
    async handleDelete() {
      const confirm = await this.$askToDelete(this.current.name || this.id)
      if (confirm) this.delete()
    },
    async delete() {
      const { error } = await this.deleteModel()

      //handle error
      if (error) {
        return this.$logException(error, {
          trackError: true,
          message: this.$t('common.error.action.delete.single', {
            resource: this.$t('common.resource.voucher.singular')
          })
        })
      }

      //handle success
      this.reset()
      this.routeTo()
    }
  }
}
</script>
