<template>
  <th-page-wrapper
    class="voucher-edit"
    color="var(--secondary-color)"
    :actions="actions"
    @save="handleSubmit"
    @close-requested="$router.push({ name: 'vouchers-list' })"
  >
    <voucher-form ref="form" />

    <!-- Go to old version -->
    <div class="flex justify-end mx-8 my-4">
      <router-link
        :to="{
          name: isNew ? 'voucher-new-v1' : 'voucher-edit-v1',
          params: $route.params
        }"
      >
        <el-button plain>
          {{ $t('common.forms.labels.old_flow') }}
        </el-button>
      </router-link>
    </div>
  </th-page-wrapper>
</template>

<script>
import VoucherForm from './components/form.vue'

export default {
  name: 'VouchersEdit',
  components: {
    VoucherForm
  },
  computed: {
    isNew() {
      return !this.$route.params.id
    },
    actions() {
      return {
        permissionPrefix: 'loyalty',
        returnedPath: { name: 'vouchers-list' },
        excludeButtons: ['delete'],
        isNew: this.isNew
      }
    }
  },
  methods: {
    handleSubmit() {
      this.$refs.form.submitForm()
    }
  }
}
</script>
