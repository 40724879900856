<template>
  <th-wrapper
    class="m-8"
    :title="$t('pages.voucher_systems.form.validity.label')"
    :subtitle="$t('pages.voucher_systems.form.validity.description')"
  >
    <el-form ref="form" :model="form" :rules="rules">
      <el-row :gutter="20">
        <!-- valid for -->
        <el-col :md="6">
          <el-form-item
            :label="$t('common.forms.labels.valid_for')"
            prop="validity_period"
          >
            <valid-to
              id="validity_period"
              v-model="validityPeriod"
              :disabled="!!form.expires_at"
              clearable
            />
          </el-form-item>
        </el-col>
        <!-- valid from -->
        <el-col :md="6">
          <el-form-item
            prop="redemption_valid_from"
            :label="$t('pages.vouchers.edit.form.redemption_valid_from.label')"
          >
            <date-picker-input
              id="redemption_valid_from"
              :placeholder="
                $t('pages.vouchers.edit.form.redemption_valid_from.placeholder')
              "
              :model-value="form.redemption_valid_from"
              @update:modelValue="
                form.redemption_valid_from = $date.getStartOfDay($event)
              "
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :md="7">
          <!-- expires_at -->
          <el-form-item
            v-if="form.expires_at !== null"
            prop="expires_at"
            :label="$t('pages.voucher_systems.form.validity.expires_at')"
          >
            <date-picker-input
              id="expires_at"
              :placeholder="
                $t('pages.vouchers.edit.form.expires_at.day.placeholder')
              "
              :model-value="form.expires_at"
              @update:modelValue="form.expires_at = $date.getEndOfDay($event)"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20" class="flex">
        <el-col :md="15">
          <!-- Days constrainers -->
          <el-form-item
            v-if="showDaysConstraints"
            prop="days_constrainers"
            :label="$t('pages.voucher_systems.form.validity.constraints')"
          >
            <days-constrainers
              id="days_constrainers"
              v-model="daysConstraints"
              class="w-full"
              @delete="showDaysConstraints = false"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :md="7">
          <!-- Add expires_at date -->
          <add-item-button
            v-if="form.expires_at === null"
            :label="$t('pages.voucher_systems.form.validity.expires_at_button')"
            @click="form.expires_at = ''"
          />
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :md="7">
          <!-- Add days constrainers -->
          <add-item-button
            v-if="!showDaysConstraints"
            :label="
              $t('pages.voucher_systems.form.validity.constraints_button')
            "
            @click="showDaysConstraints = true"
          />
        </el-col>
      </el-row>
    </el-form>
  </th-wrapper>
</template>

<script>
import get from 'just-safe-get'
import set from 'just-safe-set'
import ValidTo from '@/components/select/valid-to'
import DatePickerInput from '@/components/inputs/date-picker'
import AddItemButton from '@/components/buttons/add-item-button'
import DaysConstrainers from '@/components/inputs/days-constrainers'

export default {
  components: {
    ValidTo,
    DatePickerInput,
    AddItemButton,
    DaysConstrainers
  },
  props: {
    modelValue: {
      type: Object,
      default: () => ({})
    }
  },
  emits: ['update:modelValue'],
  computed: {
    form: {
      get() {
        return this.modelValue
      },
      set(modelValue) {
        this.$emit('update:modelValue', modelValue)
      }
    },
    validityPeriod: {
      get() {
        return get(this.form, 'validity_period.value')
      },
      set(value) {
        if (value === '') value = null
        set(this.form, 'validity_period.value', value)
      }
    },
    showDaysConstraints: {
      get() {
        return get(
          this.form,
          'constraints.time.scheduled.day_of_week.enabled',
          false
        )
      },
      set(value) {
        set(this.form, 'constraints.time.scheduled.day_of_week.enabled', value)
      }
    },
    daysConstraints: {
      get() {
        return get(this.form, 'constraints.time.scheduled.day_of_week', {})
      },
      set(value) {
        set(this.form, 'constraints.time.scheduled.day_of_week', value)
      }
    },
    rules() {
      return {} //currently no validation is needed
    }
  },
  watch: {
    'form.expires_at'() {
      this.form.validity_period.value = this.form.expires_at ? null : 1
    }
  },
  methods: {
    validate() {
      return new Promise((resolve) => {
        this.$refs.form.validate(resolve)
      })
    }
  }
}
</script>
