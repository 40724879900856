<template>
  <div class="ml-2">
    <el-form-item :label="$t('pages.voucher_systems.form.settings.attributes')">
      <el-checkbox
        v-for="attribute in attributes"
        :key="attribute.type"
        v-model="localValue[attribute.type]"
        class="w-full"
        :label="attribute.label"
      />
    </el-form-item>
  </div>
</template>

<script>
export default {
  props: {
    modelValue: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    localValue: {
      get() {
        return this.modelValue
      },
      set(modelValue) {
        this.$emit('update:modelValue', modelValue)
      }
    },
    translations() {
      return {
        partial_redemption: this.$t(
          'pages.voucher_systems.form.settings.partial_redemption'
        ),
        refundable: this.$t('pages.voucher_systems.form.settings.refundable'),
        exchange_for_cash: this.$t(
          'pages.voucher_systems.form.settings.exchange_for_cash'
        ),
        restriction_single_transaction: this.$t(
          'pages.voucher_systems.form.settings.restriction_single_transaction'
        )
      }
    },
    attributes() {
      return [
        'partial_redemption',
        'refundable',
        'exchange_for_cash',
        'restriction_single_transaction'
      ].map((type) => {
        return {
          type,
          label: this.translations[type]
        }
      })
    }
  }
}
</script>
